<template>
  <div class='progressStep px-md-4 mb-5'>
    <div class="container">
      <div class="row">
        <div class="col-4">
          <div class='step d-flex flex-column align-items-center' :class="{'now':current === 1}">
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-if="mode == 'launch'"
            >1</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else-if="mode == 'admin'"
              @click.prevent="goToAdmin('basic')">1</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else
              @click.prevent="goTo('ebasic')">1</div>
            <p>課程標題與描述</p>
          </div>
        </div>
        <div class="col-4">
          <div class='step d-flex flex-column align-items-center' :class="{'now':current === 2}">
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-if="mode == 'launch'">2</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else-if="mode == 'admin'"
              @click.prevent="goToAdmin('syllabus')">2</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else
              @click.prevent="goTo('esyllabus')">2</div>
            <p>單元編輯</p>
          </div>
        </div>
        <div class="col-4">
          <div class='step d-flex flex-column align-items-center' :class="{'now':current === 3}">
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-if="mode == 'launch'">3</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else-if="mode == 'admin'"
              @click.prevent="goToAdmin('pricing')">3</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else
              @click.prevent="goTo('epricing')">3</div>
            <p>定價與銷售</p>
          </div>
        </div>
        <!-- <div class="col-3">
          <div class='step d-flex flex-column align-items-center' :class="{'now':current === 4}">
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-if="mode == 'launch'">4</div>
            <div class="step__circle mb-3 d-flex justify-content-center align-items-center"
              v-else
              @click.prevent="goTo('eonline')">4</div>
            <p>發布課程</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Steps',
  props: ['current', 'link', 'mode', 'id'],
  data() {
    return {

    };
  },
  methods: {
    goTo(route) {
      this.$router.push(`/dashboard/edit_course/${route}/${this.id}`);
    },
    goToAdmin(route) {
      this.$router.push(`/admin/edit_course/${route}/${this.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  $main: #012970;
  $greytxt: #6c757d;
  .step {
    &.now {
      .step__circle {
        background-color: $main;
      }
    }
    &__circle {
      border-radius: 50%;
      background-color: $greytxt;
      width: 2.5rem;
      height: 2.5rem;
      color: white;
      cursor: pointer;
    }
  }
</style>
